const groupAndProcessStats = (data, format = 'yyyy-MM-dd') => {
    // Group by formatted date
    const grouped = data.reduce((acc, item) => {
        const date = new Date(item.createdAt);
        const key = format === 'yyyy-MM'
            ? `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`
            : `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;

        if (!acc[key]) acc[key] = [];
        acc[key].push(item);
        return acc;
    }, {});

    return Object.entries(grouped).map(([key, items]) => {
        // Sort items once per group
        const sortedItems = items.sort((a, b) =>
            new Date(a.createdAt) - new Date(b.createdAt)
        );

        return {
            createdAt: key,
            raw_data: sortedItems,
        };
    });
};

export default groupAndProcessStats;