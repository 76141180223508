const calculateTrafficUsed = (items) => {
    let result = [];
    let prevItem = null;

    // Avoid re-sorting if items are already processed in groupAndProcessStats
    for (let i = 0; i < items?.length; i++) {
        const item = items[i];

        if (i === 0) {
            result.push({ ...item, traffic_used: 0 });
        } else {
            // Calculate traffic used based on balance difference
            const balanceDifference = parseFloat(item.traffic_balance) - parseFloat(prevItem.traffic_balance);
            const trafficUsed = balanceDifference < 0 ? -balanceDifference : 0;

            result.push({
                ...item,
                traffic_used: parseFloat(trafficUsed)
            });
        }

        prevItem = item;
    }
    
    return result;
};

export default calculateTrafficUsed;