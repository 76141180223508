import formatDate from "./formatDate.js";

const fillMissingDates = (data) => {
    if (!data?.length) return [];

    const sortedData = [...data].sort(
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
    );

    const filledData = [];

    for (let i = 0; i < sortedData.length; i++) {
        const currentItem = sortedData[i];
        const nextItem = sortedData[i + 1];

        filledData.push({
            traffic_balance: currentItem.traffic_balance,
            createdAt: currentItem.createdAt,
        });

        if (nextItem) {
            const currentDate = new Date(currentItem.createdAt);
            const nextDate = new Date(nextItem.createdAt);

            const hoursDiff = Math.floor(
                (nextDate - currentDate) / (1000 * 60 * 60)
            );

            for (let j = 1; j < hoursDiff; j++) {
                const filledDate = new Date(
                    currentDate.getTime() + j * 60 * 60 * 1000
                );

                filledData.push({
                    traffic_balance: currentItem.traffic_balance,
                    createdAt: filledDate.toISOString(),
                });
            }
        }
    }

    return filledData.map((item) => ({
        traffic_balance: item.traffic_balance,
        createdAt: formatDate(item.createdAt, "yyyy-MM-dd h:m:s"),
    }));
};

export default fillMissingDates;