import React, { useState, useEffect } from "react";
import useDebouncedEffect from "../../../components/useDebouncedEffect.js";

import {
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    AreaChart,
    Area,
} from "recharts";

import { Helmet } from "react-helmet";
import { toast } from 'react-toastify';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import DatePicker from '../../../components/DatePicker';
import HintComponent from '../../../components/HintComponent';
import DropdownButton from '../../../components/DropdownButton';

import getData from "../../../api/residential_proxy_pool_2/getData.js";
import generateProxyLists from "../../../api/residential_proxy_pool_2/generateProxyLists.js";

import getWhitelistEntries from "../../../api/residential_proxy_pool_2/getWhitelistEntries.js";
import addWhitelistEntry from "../../../api/residential_proxy_pool_2/addWhitelistEntry.js";
import updateWhitelistEntry from "../../../api/residential_proxy_pool_2/updateWhitelistEntry.js";
import removeWhitelistEntry from "../../../api/residential_proxy_pool_2/removeWhitelistEntry.js";

import getIPSkippingLists from "../../../api/residential_proxy_pool_2/getIPSkippingLists.js";
import addIPSkippingList from "../../../api/residential_proxy_pool_2/addIPSkippingList.js";
import deleteIPSkippingList from "../../../api/residential_proxy_pool_2/deleteIPSkippingList.js";
import updateIPSkippingList from "../../../api/residential_proxy_pool_2/updateIPSkippingList.js";

import Preloader from "../../../components/Preloader/index.js";
import Popup from "../../../components/Popup/index.js";
import SelectSearchComponent from "../../../components/SelectSearchComponent";
import SelectComponent from "../../../components/SelectComponent/index.js";

import processErrors from '../../../utils/processErrors';
import formatDate from "../../../utils/formatDate.js";
import convertTrafficValue from "../../../utils/convertTrafficValue.js";
import fillMissingDates from "../../../utils/fillMissingDates.js";
import filterByDateRange from "../../../utils/filterByDateRange.js";
import groupAndProcessStats from "../../../utils/groupAndProcessStats.js";
import calculateTrafficUsed from "../../../utils/calculateTrafficUsed.js";

export const OrderResidentialProxyPool2Page = () => {
    const { t } = useTranslation();

    const user = useSelector((state) => state.user);

    const [isOpenWhitelistPopup, setIsOpenWhitelistPopup] = useState(false);
    const [isOpenWhitelistEditPopup, setIsOpenWhitelistEditPopup] = useState(false);
    const [isOpenIpskippingPopup, setIsOpenIpskippingPopup] = useState(false);

    const [currentTab, setCurrentTab] = useState('tab-configuration');

    const [residentialProxyData, setResidentialProxyData] = useState();
    const [trafficStats, setTrafficStats] = useState();
    const [selectedTrafficStatsTab, setSelectedTrafficStatsTab] = useState("resource-using");
    const [selectedTrafficUnit, setSelectedTrafficUnit] = useState({
        "name": "GB",
        "value": "gb",
    });
    const available_traffic_units = [
        {
            "name": "MB",
            "value": "mb",
        },
        {
            "name": "GB",
            "value": "gb",
        }
    ]
    const [selectedDate, setSelectedDate] = useState("2024-11-08T19:21:06.585Z");
    const available_time_formats = [
        {
            "name": t("name-sec"),
            "value": "s",
        },
        {
            "name": t("name-min"),
            "value": "m",
        },
        {
            "name": t("name-hours"),
            "value": "h",
        },
    ]

    const [selectedCountry, setSelectedCountry] = useState();
    const [selectedCity, setSelectedCity] = useState();
    const [selectedProtocol, setSelectedProtocol] = useState();
    const [selectedRotation, setSelectedRotation] = useState();
    const [selectedRotationInterval, setSelectedRotationInterval] = useState();
    const [selectedRotationIntervalTimeFormat, setSelectedRotationIntervalTimeFormat] = useState(available_time_formats[0]);
    const [selectedHostname, setSelectedHostname] = useState();
    const [selectedPort, setSelectedPort] = useState();
    const [selectedUsername, setSelectedUsername] = useState();
    const [selectedPassword, setSelectedPassword] = useState();
    const [selectedFormat, setSelectedFormat] = useState();
    const [selectedQuantity, setSelectedQuantity] = useState(10);
    const [selectedFormattedQuantity, setSelectedFormattedQuantity] = useState(10);
    const [selectedWhitelist, setSelectedWhitelist] = useState();

    const [newWhiteListEntryAvailableCities, setNewWhiteListEntryAvailableCities] = useState([]);
    const [newWhitelistEntrySelectedHighEndPool, setNewWhitelistEntrySelectedHighEndPool] = useState("");
    const [newWhitelistEntrySelectedSkipStatic, setNewWhitelistEntrySelectedSkipStatic] = useState("");
    const [newWhitelistEntrySelectedCountry, setNewWhitelistEntrySelectedCountry] = useState("");
    const [newWhitelistEntrySelectedState, setNewWhitelistEntrySelectedState] = useState("");
    const [newWhitelistEntrySelectedType, setNewWhitelistEntrySelectedType] = useState("");
    const [newWhitelistEntrySelectedRotation, setNewWhitelistEntrySelectedRotation] = useState("");
    const [newWhitelistEntrySelectedIPSkipping, setNewWhitelistEntrySelectedIPSkipping] = useState("");
    const [newWhitelistEntryConfiguration, setNewWhitelistEntryConfiguration] = useState("");
    const [newWhitelistEntrySelectedRotationInterval, setNewWhitelistEntrySelectedRotationInterval] = useState();
    const [newWhitelistEntrySelectedRotationIntervalTimeFormat, setNewWhitelistEntrySelectedRotationIntervalTimeFormat] = useState(available_time_formats[0]);

    const [newWhitelistEntrySelectedIP, setNewWhitelistEntrySelectedIP] = useState("");
    const [newWhitelistEntrySelectedPort, setNewWhitelistEntrySelectedPort] = useState("");

    const [newIPSkippingListTitle, setNewIPSkippingListTitle] = useState("");

    const [editWhitelistEntryAvailableCities, setEditWhitelistEntryAvailableCities] = useState([]);
    const [editWhitelistEntryHash, setEditWhitelistEntryHash] = useState("");
    const [editWhitelistEntrySelectedHighEndPool, setEditWhitelistEntrySelectedHighEndPool] = useState("");
    const [editWhitelistEntrySelectedSkipStatic, setEditWhitelistEntrySelectedSkipStatic] = useState("");
    const [editWhitelistEntrySelectedCountry, setEditWhitelistEntrySelectedCountry] = useState("");
    const [editWhitelistEntrySelectedState, setEditWhitelistEntrySelectedState] = useState("");
    const [editWhitelistEntrySelectedType, setEditWhitelistEntrySelectedType] = useState("");
    const [editWhitelistEntrySelectedRotation, setEditWhitelistEntrySelectedRotation] = useState("");
    const [editWhitelistEntrySelectedIPSkipping, setEditWhitelistEntrySelectedIPSkipping] = useState("");
    const [editWhitelistEntryConfiguration, setEditWhitelistEntryConfiguration] = useState("");
    const [editWhitelistEntrySelectedRotationInterval, setEditWhitelistEntrySelectedRotationInterval] = useState();
    const [editWhitelistEntrySelectedRotationIntervalTimeFormat, setEditWhitelistEntrySelectedRotationIntervalTimeFormat] = useState(available_time_formats[0]);

    const [editWhitelistEntrySelectedIP, setEditWhitelistEntrySelectedIP] = useState("");
    const [editWhitelistEntrySelectedPort, setEditWhitelistEntrySelectedPort] = useState("");

    const [proxyLists, setProxyLists] = useState([]);
    const [formattedProxyLists, setFormattedProxyLists] = useState([]);
    const [availableProtocols, setAvailableProtocols] = useState([]);
    const [availableHostnames, setAvailableHostnames] = useState([]);
    const [availableCountries, setAvailableCountries] = useState([]);
    const [availableCities, setAvailableCities] = useState([]);
    const [availablePorts, setAvailablePorts] = useState([]);
    const [availableWhitelist, setAvailableWhitelist] = useState([]);
    const [availableIPSkippingList, setAvailableIPSkippingList] = useState([]);

    function getUniquePorts(data, types) {
        const uniquePorts = {};

        // Initialize sets for the requested types
        types.forEach(type => {
            uniquePorts[type] = new Set();
        });

        data.forEach(item => {
            item.ports.forEach(portObj => {
                if (types.includes(portObj.name)) {
                    // Add main port
                    uniquePorts[portObj.name].add(portObj.port);

                    // Add alternative ports
                    portObj.alternative_ports.forEach(altPort => {
                        uniquePorts[portObj.name].add(altPort);
                    });
                }
            });
        });

        // Convert sets to arrays with the specified format
        const result = {};
        types.forEach(type => {
            result[type] = Array.from(uniquePorts[type]).map(port => ({
                name: port,
                value: port
            }));
        });

        return result;
    }

    const downloadFile = (data, fileName, fileType) => {
        const blob = new Blob([data], { type: fileType });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
        URL.revokeObjectURL(url);

        toast.success(t('name-download-success'));
    };

    const handleSelectedRotationInterval = (value) => {
        const min = 1;
        let max = selectedRotationIntervalTimeFormat.value === "s" ? 59 : selectedRotationIntervalTimeFormat.value === "m" ? 59 : 24;

        if (value < min) {
            setSelectedRotationInterval(min);
        } else if (value > max) {
            setSelectedRotationInterval(max);
        } else {
            setSelectedRotationInterval(value);
        }
    };

    const handleNewWhitelistEntrySelectedRotationInterval = (value) => {
        const min = 1;
        let max = newWhitelistEntrySelectedRotationIntervalTimeFormat.value === "s" ? 59 : newWhitelistEntrySelectedRotationIntervalTimeFormat.value === "m" ? 59 : 24;

        if (value < min) {
            setNewWhitelistEntrySelectedRotationInterval(min);
        } else if (value > max) {
            setNewWhitelistEntrySelectedRotationInterval(max);
        } else {
            setNewWhitelistEntrySelectedRotationInterval(value);
        }
    };

    const handleEditWhitelistEntrySelectedRotationInterval = (value) => {
        const min = 1;
        let max = editWhitelistEntrySelectedRotationInterval.value === "s" ? 59 : editWhitelistEntrySelectedRotationIntervalTimeFormat.value === "m" ? 59 : 24;

        if (value < min) {
            setEditWhitelistEntrySelectedRotationInterval(min);
        } else if (value > max) {
            setEditWhitelistEntrySelectedRotationInterval(max);
        } else {
            setEditWhitelistEntrySelectedRotationInterval(value);
        }
    };

    const downloadCSV = () => {
        const csvData = proxyLists.map(item => item).join('\n');
        downloadFile(csvData, 'proxyList.csv', 'text/csv');
    };

    const downloadTXT = () => {
        const txtData = proxyLists.join('\n');
        downloadFile(txtData, 'proxyList.txt', 'text/plain');
    };

    const downloadFormattedCSV = () => {
        const csvData = formattedProxyLists.map(item => item).join('\n');
        downloadFile(csvData, 'proxyList.csv', 'text/csv');
    };

    const downloadFormattedTXT = () => {
        const txtData = formattedProxyLists.join('\n');
        downloadFile(txtData, 'proxyList.txt', 'text/plain');
    };

    const availableProxylistDownloadOptions = [
        { label: '.CSV', action: downloadCSV },
        { label: '.TXT', action: downloadTXT },
    ];
    const availableFormattedProxylistDownloadOptions = [
        { label: '.CSV', action: downloadFormattedCSV },
        { label: '.TXT', action: downloadFormattedTXT },
    ];

    // IP skipping variables

    const [ipSkippingEditItem, setIpSkippingEditItem] = useState({});

    const handleIPSkippingEditItemAddRange = () => {
        const newRange = '0.0.0.0/0';

        const updatedRanges = [...(ipSkippingEditItem?.items || []), { "ip_range": newRange }];

        setIpSkippingEditItem({
            ...ipSkippingEditItem,
            items: updatedRanges
        });
    };

    const handleIpSkippingEditItem = (item) => {
        setIsOpenIpskippingPopup(true);
        setIpSkippingEditItem(item);
    };

    const handleNewWhitelistEntryResetOptions = () => {
        setNewWhitelistEntrySelectedHighEndPool(false)
        setNewWhitelistEntrySelectedSkipStatic(false)
        setNewWhitelistEntrySelectedCountry("")
        setNewWhitelistEntrySelectedState("")
        setNewWhitelistEntrySelectedRotation("")
        setNewWhitelistEntrySelectedType("")
        setNewWhitelistEntrySelectedIP("")
        setNewWhitelistEntrySelectedPort("")
    };

    const handleEditWhitelistEntryResetOptions = () => {
        setEditWhitelistEntrySelectedHighEndPool(false)
        setEditWhitelistEntrySelectedSkipStatic(false)
        setEditWhitelistEntrySelectedCountry("")
        setEditWhitelistEntrySelectedState("")
        setEditWhitelistEntrySelectedRotation("")
        setEditWhitelistEntrySelectedType("")
        setEditWhitelistEntrySelectedIP("")
        setEditWhitelistEntrySelectedPort("")
    };

    const handleIPSkippingEditItemRemoveRange = (index) => {
        const updatedRanges = ipSkippingEditItem.items.filter((_, i) => i !== index);

        if (updatedRanges.length > 0) {
            setIpSkippingEditItem({
                ...ipSkippingEditItem,
                items: updatedRanges
            });
        }
    };

    const fetchResidentialProxyData = async () => {
        const data = await getData(user.token);
        if (data?.message === 'Forbidden') {
            window.location.href = '/login'
        }
        setResidentialProxyData(data)
        setSelectedUsername(data?.available_usernames ? data?.available_usernames[0] : "")
        setSelectedPassword(data?.available_passwords ? data?.available_passwords[0] : "")
        setSelectedRotation(data?.available_rotations ? data?.available_rotations[0] : "")
        setAvailableCountries(data?.available_countries?.countries ? data.available_countries.countries : [])
        setSelectedFormat(data?.available_formats ? data?.available_formats[0] : "")

        if (data?.traffic_stats && data?.traffic_stats?.length > 0) {
            const formatted_traffic_stats = data?.traffic_stats?.map((item) => ({
                traffic_balance: item?.traffic_balance,
                createdAt: formatDate(item?.createdAt, "date-without-year")
            })) || [];
            setTrafficStats(formatted_traffic_stats);

            const formatDateForInput = (dateString) => {
                try {
                    const date = new Date(dateString);
                    return date.toISOString().split('T')[0];
                } catch (error) {
                    return "";
                }
            };

            setSelectedDate({
                start_date: formatDateForInput(data?.traffic_stats?.[data?.traffic_stats?.length - 1]?.createdAt),
                end_date: formatDateForInput(new Date(Date.now() + 24 * 60 * 60 * 1000))
            });            
        }

        if (data?.entry_nodes) {
            const ports = getUniquePorts(data.entry_nodes, ["http|https", "socks5"]);
            setAvailablePorts(ports);
        }

        if (data?.whitelist && data?.whitelist?.length > 0) {
            setAvailableWhitelist(data?.whitelist)
        }
        if (data?.ips_skipping && data?.ips_skipping?.length > 0) {
            setAvailableIPSkippingList(data?.ips_skipping)
        }
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    function generateRandomString(length) {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    }

    const parseConfigurationValue = (configurationValue) => {
        const variables = {
            editWhitelistEntrySelectedSkipStatic: false,
            editWhitelistEntrySelectedHighEndPool: false,
            editWhitelistEntrySelectedCountry: null,
            editWhitelistEntrySelectedState: null,
            editWhitelistEntrySelectedCity: null,
            editWhitelistEntrySelectedRotation: residentialProxyData?.available_rotations.find((item) => item.value === "random"),
            editWhitelistEntrySelectedRotationInterval: '',
            editWhitelistEntrySelectedRotationIntervalTimeFormat: available_time_formats[0],
        };

        const parts = configurationValue.startsWith("_")
            ? configurationValue.slice(1).split("_")
            : configurationValue.split("_");

        const countryPrefix = residentialProxyData?.available_countries?.prefix || "";
        const statePrefix = residentialProxyData?.available_countries?.countries[0]?.states?.prefix || "";
        const cityPrefix = residentialProxyData?.available_countries?.countries[0]?.cities?.prefix || "";
        const rotationPrefix = "_session";
        const rotationIntervalPrefix = "_lifetime";

        parts.forEach((part) => {
            if (part.startsWith(countryPrefix.replace("_", ""))) {
                const countryCode = part.replace(countryPrefix.replace("_", ""), "");
                variables.editWhitelistEntrySelectedCountry = availableCountries.find(
                    (country) => country.code === countryCode
                );
            }

            if (part.startsWith(statePrefix.slice(1))) {
                const stateCode = part.replace(statePrefix, "");
                variables.editWhitelistEntrySelectedState = variables.editWhitelistEntrySelectedCountry?.states?.options.find(
                    (state) => state.code === stateCode
                );
            }

            if (part.startsWith(cityPrefix.slice(1))) {
                const cityCode = part.replace(cityPrefix.replace("_", ""), "");
                variables.editWhitelistEntrySelectedCity =
                    variables.editWhitelistEntrySelectedState?.cities?.options.find(
                        (city) => city.code === cityCode
                    ) || variables.editWhitelistEntrySelectedCountry?.cities?.options.find(
                        (city) => city.code === cityCode
                    );
            }

            if (part.startsWith(rotationPrefix.slice(1))) {
                variables.editWhitelistEntrySelectedRotation = residentialProxyData?.available_rotations.find((item) => item.value === "sticky");
            }

            if (part.startsWith(rotationIntervalPrefix.slice(1))) {
                const rotationInterval = part.replace(rotationIntervalPrefix.replace("_", ""), "");
                const rotationIntervalTimeFormat = part.slice(-1);

                const positiveRotationInterval = Math.abs(parseInt(rotationInterval));

                variables.editWhitelistEntrySelectedRotationInterval = positiveRotationInterval;
                variables.editWhitelistEntrySelectedRotationIntervalTimeFormat = available_time_formats.find((item) => item.value === rotationIntervalTimeFormat);
            }

            if (part === "skipstatic-1") {
                variables.editWhitelistEntrySelectedSkipStatic = true;
            }
            if (part === "streaming-1") {
                variables.editWhitelistEntrySelectedHighEndPool = true;
            }
        });

        setEditWhitelistEntrySelectedRotation(variables.editWhitelistEntrySelectedRotation);
        setEditWhitelistEntrySelectedRotationInterval(variables.editWhitelistEntrySelectedRotationInterval);
        setEditWhitelistEntrySelectedRotationIntervalTimeFormat(variables.editWhitelistEntrySelectedRotationIntervalTimeFormat);
        setEditWhitelistEntrySelectedSkipStatic(variables.editWhitelistEntrySelectedSkipStatic);
        setEditWhitelistEntrySelectedHighEndPool(variables.editWhitelistEntrySelectedHighEndPool);
        setEditWhitelistEntrySelectedCountry(variables.editWhitelistEntrySelectedCountry);
        setEditWhitelistEntrySelectedState(variables.editWhitelistEntrySelectedCity);
    };

    const fetchGenerateProxyLists = async () => {
        try {
            if (!user.token) {
                window.location.href = '/login';
                return;
            }

            const location = [
                residentialProxyData?.available_countries?.prefix && selectedCountry?.code
                    ? residentialProxyData.available_countries.prefix + selectedCountry.code
                    : "",
                selectedCountry?.cities?.prefix && selectedCity?.value
                    ? selectedCountry.cities.prefix + selectedCity.value
                    : ""
            ].join('');

            const queries = {
                format: selectedFormat.value,
                hostname: selectedHostname.value,
                port: selectedProtocol.value,
                rotation: selectedRotation.value,
                proxy_count: selectedQuantity
            }

            if (location) {
                queries.location = location;
            }

            if (selectedRotation.value === 'sticky' && selectedRotationInterval > 0 && selectedRotationIntervalTimeFormat.value) {
                queries.lifetime = selectedRotationInterval + selectedRotationIntervalTimeFormat.value
            }

            const data = await generateProxyLists(queries, user.token);

            if (data?.message === 'Forbidden') {
                window.location.href = '/login';
                return;
            }

            if (data?.message === 'Server error') {
                console.error('Server error');
                return;
            }

            setProxyLists(data);
        } catch (error) {
            console.error('An error occurred:', error);
        }
    };

    const fetchIPSkippingLists = async () => {
        try {
            const ipskipping_data = await getIPSkippingLists(user.token);
            setAvailableIPSkippingList(ipskipping_data);
        } catch (error) {
            console.error('An error occurred:', error);
        }
    }

    const handleIPSkippingListAdd = async () => {
        try {
            const response = await addIPSkippingList({ title: newIPSkippingListTitle }, user.token);
            const responseJson = await response.json();

            if (!response.ok) {
                const errorMessage = processErrors(responseJson);
                throw new Error(errorMessage);
            }

            setNewIPSkippingListTitle('');
            fetchIPSkippingLists();
            toast.success(t('notification-ipskipping-add-success'));
        } catch (error) {
            toast.error(t(error.message));
        }
    };

    const handleIPSkippingListUpdate = async () => {
        try {
            const response = await updateIPSkippingList({
                hash: ipSkippingEditItem?.hash,
                title: ipSkippingEditItem?.title,
                ip_ranges: ipSkippingEditItem?.items
            }, user.token);
            const responseJson = await response.json();

            if (!response.ok) {
                const errorMessage = processErrors(responseJson);
                throw new Error(errorMessage);
            }

            toast.success(t('name-ipskipping-update-success'));

            fetchIPSkippingLists();
            setIsOpenIpskippingPopup(false);
        } catch (error) {
            toast.error(t(error.message));
        }
    };

    const handleIPSkippingListRemove = async (hash) => {
        try {
            const isConfirmed = window.confirm(t("name-confirm"));
            if (!isConfirmed) {
                return;
            }

            const response = await deleteIPSkippingList({ hash: hash }, user.token);
            const responseJson = await response.json();

            if (!response.ok) {
                const errorMessage = processErrors(responseJson);
                throw new Error(errorMessage);
            }

            fetchIPSkippingLists();
            toast.success(t('name-ipskipping-remove-success'));
        } catch (error) {
            console.error('An error occurred:', error);
        }
    };

    const handleWhitelistEntryAdd = async () => {
        try {
            var params = {}

            if (newWhitelistEntrySelectedRotation.value === 'sticky' && (!newWhitelistEntrySelectedRotationInterval || !newWhitelistEntrySelectedRotationIntervalTimeFormat)) {
                throw new Error('name-rotation-interval-required');
            }

            if (newWhitelistEntrySelectedIP) {
                params.ip = newWhitelistEntrySelectedIP
            }

            if (newWhitelistEntrySelectedPort) {
                params.port = newWhitelistEntrySelectedPort?.value
            }

            if (newWhitelistEntryConfiguration) {
                params.configuration = newWhitelistEntryConfiguration
            }

            const response = await addWhitelistEntry(params, user.token);
            const responseJson = await response.json();

            if (!response.ok) {
                const errorMessage = processErrors(responseJson);
                throw new Error(errorMessage);
            }

            setIsOpenWhitelistPopup(false);
            handleNewWhitelistEntryResetOptions()
            toast.success(t('name-whitelist-add-success'));

            const whitelist_data = await getWhitelistEntries(user.token);
            setAvailableWhitelist(whitelist_data);
        } catch (error) {
            toast.error(t(error.message));
        }
    };

    const handleWhitelistEntryRemove = async (hash) => {
        const isConfirmed = window.confirm(t("name-confirm"));
        if (!isConfirmed) {
            return;
        }

        const data = await removeWhitelistEntry({
            whitelist_entry_hash: hash
        }, user.token);
        if (data?.message === 'Forbidden') {
            window.location.href = '/login'
        }
        if (data?.message === 'Server error') {
            toast.error(t('name-whitelist-remove-error'));
            return
        }
        toast.success(t('name-whitelist-remove-success'));

        const whitelist_data = await getWhitelistEntries(user.token);
        setAvailableWhitelist(whitelist_data);
    };

    const handleWhitelistEntryEdit = async (item) => {
        if (item?.configuration) {
            parseConfigurationValue(item.configuration);
            setEditWhitelistEntryConfiguration(item.configuration);
        }
        if (item?.ip) {
            setEditWhitelistEntrySelectedIP(item.ip);
        }
        if (item?.port) {
            const filteredPortItem = availablePorts[item.type].filter((itemPort) => (itemPort?.value === item?.port));

            if (filteredPortItem?.length > 0) {
                setEditWhitelistEntrySelectedPort(filteredPortItem[0]);
            }
        }
        if (item?.type) {
            const filteredTypeItem = availableProtocols.filter((itemType) => (itemType?.value === item?.type));

            if (filteredTypeItem?.length > 0) {
                setEditWhitelistEntrySelectedType(filteredTypeItem[0]);
            }
        }
        if (item?.hash) {
            setEditWhitelistEntryHash(item.hash);
        }
        setIsOpenWhitelistEditPopup(true);
    };

    const handleWhitelistEntryEditSubmit = async () => {
        try {
            var params = {}

            if (editWhitelistEntryHash) {
                params.whitelist_entry_hash = editWhitelistEntryHash;
            }

            if (editWhitelistEntryConfiguration) {
                params.configuration = editWhitelistEntryConfiguration
            }

            const response = await updateWhitelistEntry(params, user.token);
            const responseJson = await response.json();

            if (!response.ok) {
                const errorMessage = processErrors(responseJson);
                throw new Error(errorMessage);
            }

            setIsOpenWhitelistEditPopup(false);
            handleEditWhitelistEntryResetOptions()
            toast.success(t('name-whitelist-edit-success'));

            const whitelist_data = await getWhitelistEntries(user.token);
            setAvailableWhitelist(whitelist_data);
        } catch (error) {
            toast.error(t(error.message));
        }
    };

    // useEffect(() => {
    //     setSelectedCity(selectedCountry?.cities?.options.map((item) => ({
    //         name: item.name,
    //         value: item.code
    //     }))[0])
    // }, [selectedCountry]);

    // useEffect(() => {
    //     setNewWhitelistEntrySelectedState(newWhitelistEntrySelectedCountry?.cities?.options.map((item) => ({
    //         name: item.name,
    //         value: item.code
    //     }))[0])
    // }, [newWhitelistEntrySelectedCountry]);

    // useEffect(() => {
    //     setEditWhitelistEntrySelectedState(editWhitelistEntrySelectedCountry?.cities?.options.map((item) => ({
    //         name: item.name,
    //         value: item.code
    //     }))[0])
    // }, [editWhitelistEntrySelectedCountry]);

    const handleCopy = () => {
        const textareaValue = proxyLists.join('\n');
        navigator.clipboard.writeText(textareaValue).then(() => {
            toast.success(t('name-copy-success'));
        }).catch(err => {
            console.error('Failed to copy text: ', err);
        });
    };

    const handleFormattedCopy = () => {
        const textareaValue = formattedProxyLists.join('\n');
        navigator.clipboard.writeText(textareaValue).then(() => {
            toast.success(t('name-copy-success'));
        }).catch(err => {
            console.error('Failed to copy text: ', err);
        });
    };

    const CustomTooltip2 = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <>
                    <div className="graph-tooltip">
                        <div className="graph-tooltip__value">{payload[0].value} <span className="green">{selectedTrafficUnit?.name}</span></div>
                        <div className="graph-tooltip__name">{label}</div>
                    </div>
                </>
            );
        }
    };

    useEffect(() => {
        const filtered_cities = newWhitelistEntrySelectedCountry?.cities?.options ? newWhitelistEntrySelectedCountry.cities.options.map((item) => ({
            name: item.name,
            value: item.code
        })) : []

        setNewWhiteListEntryAvailableCities(filtered_cities);
        setNewWhitelistEntrySelectedState(null);
    }, [newWhitelistEntrySelectedCountry]);

    useEffect(() => {
        const filtered_cities = editWhitelistEntrySelectedCountry?.cities?.options ? editWhitelistEntrySelectedCountry.cities.options.map((item) => ({
            name: item.name,
            value: item.code
        })) : []

        setEditWhitelistEntryAvailableCities(filtered_cities);
        setEditWhitelistEntrySelectedState(null);
    }, [editWhitelistEntrySelectedCountry]);

    useEffect(() => {
        if (selectedWhitelist) {
            let formatted_proxy_lists = []

            for (let index = 0; index < selectedFormattedQuantity; index++) {
                formatted_proxy_lists.push(`${availableHostnames[0].value}:${selectedWhitelist.value.port}`)
            }

            setFormattedProxyLists(formatted_proxy_lists)
        }
    }, [selectedWhitelist, selectedFormattedQuantity]);

    useEffect(() => {
        const filtered_cities = selectedCountry?.cities?.options ? selectedCountry.cities.options.map((item) => ({
            name: item.name,
            value: item.code
        })) : []

        setAvailableCities(filtered_cities);
        setSelectedCity(null);
    }, [selectedCountry]);

    useEffect(() => {
        if (!residentialProxyData?.traffic_stats?.length) return;

        const filteredStats = filterByDateRange(
            fillMissingDates(residentialProxyData.traffic_stats),
            selectedDate?.start_date,
            selectedDate?.end_date
        );

        const rangeDays = Math.ceil((new Date(selectedDate?.end_date) - new Date(selectedDate?.start_date)) / (1000 * 60 * 60 * 24)) + 1;

        const groupingFormat = rangeDays > 180 ? "yyyy-MM" : "yyyy-MM-dd";

        const sortedFilteredStats = filteredStats.sort((a, b) =>
            new Date(a.createdAt) - new Date(b.createdAt)
        );

        let processedStats = [];

        if (rangeDays > 2) {
            processedStats = groupAndProcessStats(sortedFilteredStats, groupingFormat);
        } else {
            processedStats = sortedFilteredStats;
        }

        if (selectedTrafficStatsTab === "resource-left") {
            let trafficStats = [];

            if (rangeDays < 2) {
                trafficStats = processedStats;
            } else {
                trafficStats = processedStats.map(item => {
                    const maxValue = Math.max(...item.raw_data.map(data => data.traffic_balance || 0));
    
                    return {
                        createdAt: item.createdAt,
                        traffic_balance: convertTrafficValue(maxValue, selectedTrafficUnit.value),
                    }
                })
            }

            setTrafficStats(trafficStats);
        } else if (selectedTrafficStatsTab === "resource-using") {
            let usageStats = [];

            if (rangeDays > 2) {
                usageStats = processedStats.map(item => {
                    const totalUsed = calculateTrafficUsed(item.raw_data).reduce((acc, curr) => acc + (curr.traffic_used || 0), 0);

                    return {
                        createdAt: item.createdAt,
                        traffic_used: convertTrafficValue(totalUsed, selectedTrafficUnit.value),
                    };
                });
                
            } else {
                usageStats = calculateTrafficUsed(processedStats);
            }

            setTrafficStats(usageStats.map(item => ({
                createdAt: item.createdAt,
                traffic_balance: item.traffic_used || 0,
            })));
        }
    }, [residentialProxyData, selectedTrafficStatsTab, selectedTrafficUnit, selectedDate]);

    useEffect(() => {
        if (residentialProxyData?.entry_nodes) {
            const hostnames = residentialProxyData.entry_nodes.map((item) => ({ name: item.dns, value: item.dns }));
            setAvailableHostnames(hostnames);
            if (hostnames.length > 0) {
                setSelectedHostname(hostnames[0]);
            }
        }
    }, [residentialProxyData]);

    useEffect(() => {
        if (selectedHostname && residentialProxyData) {
            const protocols = residentialProxyData.entry_nodes
                .filter((item) => item?.dns === selectedHostname?.value)
                .flatMap((item) => item?.ports || [])
                .map((item) => ({ name: item.name, value: item.name }));
            setAvailableProtocols(protocols);
            if (protocols.length > 0) {
                setSelectedProtocol(protocols[0]);
            }
        }
    }, [selectedHostname, residentialProxyData]);

    useEffect(() => {
        if (selectedProtocol && selectedHostname && availablePorts) {
            const port = availablePorts[selectedProtocol.value]?.[0];

            if (port) {
                setSelectedPort(port);
            }
        }
    }, [selectedProtocol, selectedHostname, availablePorts]);

    useEffect(() => {
        let configuration_value = "";

        configuration_value += [
            residentialProxyData?.available_countries?.prefix && newWhitelistEntrySelectedCountry?.code
                ? `${residentialProxyData.available_countries.prefix}${newWhitelistEntrySelectedCountry.code}`
                : "",
            newWhitelistEntrySelectedCountry?.cities?.prefix && newWhitelistEntrySelectedState?.value
                ? `${newWhitelistEntrySelectedCountry.cities.prefix}${newWhitelistEntrySelectedState.value}`
                : ""
        ].join('');

        if (newWhitelistEntrySelectedSkipStatic) {
            configuration_value += "_skipstatic-1";
        }

        if (newWhitelistEntrySelectedHighEndPool) {
            configuration_value += "_streaming-1";
        }

        if (newWhitelistEntrySelectedRotation?.value === "sticky") {
            configuration_value += "_session-" + generateRandomString(6);
        }

        if (newWhitelistEntrySelectedRotation.value === 'sticky' && newWhitelistEntrySelectedRotationInterval > 0 && newWhitelistEntrySelectedRotationIntervalTimeFormat.value) {
            configuration_value += "_lifetime-" + newWhitelistEntrySelectedRotationInterval + newWhitelistEntrySelectedRotationIntervalTimeFormat.value
        }

        setNewWhitelistEntryConfiguration(configuration_value)
    }, [
        newWhitelistEntrySelectedSkipStatic,
        newWhitelistEntrySelectedHighEndPool,
        newWhitelistEntrySelectedCountry,
        newWhitelistEntrySelectedState,
        newWhitelistEntrySelectedIP,
        newWhitelistEntrySelectedPort,
        newWhitelistEntrySelectedType,
        newWhitelistEntrySelectedIPSkipping,
        newWhitelistEntrySelectedRotation,
        newWhitelistEntrySelectedRotationInterval,
        newWhitelistEntrySelectedRotationIntervalTimeFormat,
        residentialProxyData
    ]);

    useEffect(() => {
        let configuration_value = "";

        configuration_value += [
            residentialProxyData?.available_countries?.prefix && editWhitelistEntrySelectedCountry?.code
                ? `${residentialProxyData.available_countries.prefix}${editWhitelistEntrySelectedCountry.code}`
                : "",
            editWhitelistEntrySelectedCountry?.cities?.prefix && editWhitelistEntrySelectedState?.value
                ? `${editWhitelistEntrySelectedCountry.cities.prefix}${editWhitelistEntrySelectedState.value}`
                : ""
        ].join('');

        if (editWhitelistEntrySelectedSkipStatic) {
            configuration_value += "_skipstatic-1";
        }

        if (editWhitelistEntrySelectedHighEndPool) {
            configuration_value += "_streaming-1";
        }

        if (editWhitelistEntrySelectedRotation.value === 'sticky' && editWhitelistEntrySelectedRotationInterval > 0 && editWhitelistEntrySelectedRotationIntervalTimeFormat.value) {
            configuration_value += "_lifetime-" + editWhitelistEntrySelectedRotationInterval + editWhitelistEntrySelectedRotationIntervalTimeFormat.value
        }

        setEditWhitelistEntryConfiguration(configuration_value)
    }, [editWhitelistEntrySelectedSkipStatic,
        editWhitelistEntrySelectedHighEndPool,
        editWhitelistEntrySelectedCountry,
        editWhitelistEntrySelectedState,
        editWhitelistEntrySelectedIP,
        editWhitelistEntrySelectedPort,
        editWhitelistEntrySelectedType,
        editWhitelistEntrySelectedIPSkipping,
        editWhitelistEntrySelectedRotation,
        editWhitelistEntrySelectedRotationInterval,
        editWhitelistEntrySelectedRotationIntervalTimeFormat,
        residentialProxyData
    ]);

    useEffect(() => {
        fetchResidentialProxyData();
    }, []);

    useDebouncedEffect(() => {
        if (selectedFormat && selectedHostname && selectedPort && selectedRotation && selectedQuantity) {
            fetchGenerateProxyLists();
        }
    }, [selectedFormat, selectedHostname, selectedPort, selectedRotation, selectedRotationInterval, selectedRotationIntervalTimeFormat, selectedCountry, selectedCity, selectedQuantity], 1000);

    return (
        <>
            <Helmet>
                <title>{t("page-title-account")}</title>
            </Helmet>
            {
                !residentialProxyData ? <Preloader></Preloader>
                    :
                    <>
                        <div className="tabs-wrapper">
                            <div
                                className={"tab-item " + (currentTab === 'tab-configuration' ? 'selected' : "")}
                                onClick={() => setCurrentTab('tab-configuration')}
                            >
                                {t('name-configuration')}
                            </div>
                            <div
                                className={"tab-item " + (currentTab === 'tab-whitelist' ? 'selected' : "")}
                                onClick={() => setCurrentTab('tab-whitelist')}
                            >
                                {t('name-whitelist')}
                            </div>
                            <div
                                className={"tab-item " + (currentTab === 'tab-ipskipping' ? 'selected' : "")}
                                onClick={() => setCurrentTab('tab-ipskipping')}
                            >
                                {t('name-ipskipping')}
                            </div>
                        </div>
                        {
                            currentTab === 'tab-configuration' &&
                                residentialProxyData &&
                                residentialProxyData?.available_countries &&
                                residentialProxyData?.available_rotations &&
                                residentialProxyData?.traffic_available &&
                                residentialProxyData?.available_formats ?
                                <div className="proxylist-wrapper">
                                    <div className="grid-wrapper">
                                        {
                                            residentialProxyData &&
                                            residentialProxyData?.available_countries &&
                                            <div className="form">
                                                <div className="form-header">
                                                    <h2 className="text-h2">{t('name-proxy-access')}</h2>
                                                </div>
                                                <div className="grid-wrapper">
                                                    <SelectSearchComponent label={t('name-country-or-region')} multiple={false} items={availableCountries} selectedItems={selectedCountry} onItemsSelected={setSelectedCountry}></SelectSearchComponent>
                                                    <SelectSearchComponent
                                                        label={t('name-city')}
                                                        multiple={false}
                                                        items={availableCities}
                                                        disabled={!availableCities || availableCities?.length <= 0}
                                                        selectedItems={selectedCity}
                                                        onItemsSelected={setSelectedCity}
                                                    ></SelectSearchComponent>
                                                </div>
                                                <SelectComponent label={t('name-proxy-host-name')} items={availableHostnames} selectedItem={selectedHostname} onItemSelected={setSelectedHostname}></SelectComponent>
                                                {/* <div className="grid-wrapper">
                                                    {
                                                        availablePorts && selectedProtocol && <SelectComponent label={t('name-port-proxy')} items={availablePorts?.[selectedProtocol.value] || []} selectedItem={selectedPort} onItemSelected={setSelectedPort}></SelectComponent>
                                                    }
                                                </div> */}
                                                <div className="grid-wrapper">
                                                    <SelectComponent label={t('name-protocol')} items={availableProtocols} selectedItem={selectedProtocol} onItemSelected={setSelectedProtocol}></SelectComponent>
                                                    <SelectComponent label={t('name-rotation')} items={residentialProxyData.available_rotations} selectedItem={selectedRotation} onItemSelected={setSelectedRotation}></SelectComponent>
                                                </div>
                                                <div className="grid-wrapper">
                                                    <SelectComponent label={t('name-username')} items={residentialProxyData.available_usernames} selectedItem={selectedUsername} onItemSelected={setSelectedUsername}></SelectComponent>
                                                    <SelectComponent label={t('name-password')} items={residentialProxyData.available_passwords} selectedItem={selectedPassword} onItemSelected={setSelectedPassword}></SelectComponent>
                                                </div>
                                                <div className="grid-wrapper">
                                                    <div className="form-section">
                                                        <div className="form-section__header">
                                                            <h3 className="text-h3">{t("name-rotation-interval")}</h3>
                                                        </div>
                                                        <div className="form-section__content">
                                                            <div className="input-wrapper_fade">
                                                                <input className="input" disabled={selectedRotation?.value !== 'sticky'} min={1} max={selectedRotationIntervalTimeFormat.value === "s" ? 86400 : selectedRotationIntervalTimeFormat.value === "m" ? 1440 : 24} value={selectedRotationInterval} placeholder={`${t("name-from")} 1 ${t("name-to")} ${selectedRotationIntervalTimeFormat.value === "s" ? 86400 : selectedRotationIntervalTimeFormat.value === "m" ? 1440 : 24}`} onChange={(e) => (handleSelectedRotationInterval(e.target.value))} type="number" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <SelectComponent label={t('name-time-format')} disabled={selectedRotation?.value !== 'sticky'} items={available_time_formats} selectedItem={selectedRotationIntervalTimeFormat} onItemSelected={setSelectedRotationIntervalTimeFormat}></SelectComponent>
                                                </div>
                                                <label className="label">{t('name-curl-example')}</label>
                                                <div className="input-wrapper_fade">
                                                    <input className="input" type="text" value={`$ curl -v -x http://${selectedUsername?.value}:${selectedPassword?.value}@${selectedHostname?.value}:${12321} -L https://ipv4.icanhazip.com`} disabled />
                                                </div>
                                            </div>
                                        }
                                        <div className="form">
                                            <div className="form-header">
                                                <h2 className="text-h2">{t('name-formatted-proxy-list')}</h2>
                                            </div>
                                            <textarea
                                                className="textarea"
                                                disabled
                                                value={proxyLists && proxyLists?.length > 0 ? proxyLists.join('\n') : ""}
                                                onChange={(e) => (setProxyLists(e.target.value))}
                                                name=""
                                                id=""
                                            ></textarea>
                                            <div className="grid-wrapper" style={{ alignItems: "center" }}>
                                                <div className="primary-btn" onClick={handleCopy}>{t('name-copy')}</div>
                                                <DropdownButton title={t("button-downloads-list")} options={availableProxylistDownloadOptions} />
                                            </div>
                                            <div className="form-section">
                                                <div className="form-section__header">
                                                    <h3 className="text-h3">{t("name-format")}</h3>
                                                </div>
                                                <div className="form-section__content">
                                                    <SelectComponent items={residentialProxyData.available_formats} selectedItem={selectedFormat} onItemSelected={setSelectedFormat}></SelectComponent>
                                                </div>
                                            </div>
                                            <div className="form-section">
                                                <div className="form-section__header">
                                                    <h3 className="text-h3">{t("name-count")}</h3>
                                                </div>
                                                <div className="form-section__content">
                                                    <div className="input-wrapper_fade">
                                                        <input className="input" value={selectedQuantity} onChange={(e) => (setSelectedQuantity(Math.min(e.target.value, 1000)))} min={1} max={1000} type="number" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        residentialProxyData?.traffic_available ?
                                            <div className="form">
                                                <div className="form-header">
                                                    <h2 className="text-h2">{t('name-available-traffic')}</h2>
                                                </div>
                                                <div className="input-wrapper_fade">
                                                    <input className="input traffic-left" style={{ textAlign: "center", opacity: 1, cursor: "auto" }} value={Number(residentialProxyData.traffic_available).toFixed(3) + ' GB'} disabled={true} type="text" />
                                                </div>
                                            </div>
                                            : ""
                                    }
                                    <div className="form">
                                        <div className="form-header">
                                            <div>
                                                <h2 className="text-h2">{t('name-statistics')}</h2>
                                                <div className="graph-tabs">
                                                    <div
                                                        className={`graph-tabs__item ${selectedTrafficStatsTab === "resource-using" ? "selected" : ""}`}
                                                        onClick={() => setSelectedTrafficStatsTab("resource-using")}
                                                    >
                                                        {t('name-resource-using')}
                                                    </div>
                                                    {/* <div
                                                        className={`graph-tabs__item ${selectedTrafficStatsTab === "resource-left" ? "selected" : ""}`}
                                                        onClick={() => setSelectedTrafficStatsTab("resource-left")}
                                                    >
                                                        {t('name-value-resources-left')}
                                                    </div> */}
                                                </div>

                                            </div>
                                            <div>
                                                <SelectComponent items={available_traffic_units} selectedItem={selectedTrafficUnit} onItemSelected={setSelectedTrafficUnit}></SelectComponent>
                                                <DatePicker
                                                    mode="range"
                                                    allowFutureDates={false}
                                                    initialSelectedDate={selectedDate}
                                                    onDateChange={handleDateChange}
                                                />
                                            </div>
                                        </div>
                                        {
                                            trafficStats && trafficStats?.length > 0 ?
                                                <ResponsiveContainer width="100%" height={250}>
                                                    <AreaChart
                                                        data={trafficStats}
                                                        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                                                    >
                                                        <defs>
                                                            <linearGradient id="colorDifficulty" x1="0" y1="0" x2="0" y2="1">
                                                                <stop offset="5%" stopColor="#A038AA" stopOpacity={0.15} />
                                                                <stop offset="95%" stopColor="#A038AA" stopOpacity={0} />
                                                            </linearGradient>
                                                        </defs>
                                                        <CartesianGrid />
                                                        <XAxis dataKey="createdAt" label={{ value: '', position: 'insideBottomRight', offset: 0 }} />
                                                        <YAxis
                                                            dataKey="traffic_balance"
                                                            label={{ value: selectedTrafficUnit?.name, angle: -90, position: 'insideLeft' }}
                                                            domain={[0, Number((Math.max(...trafficStats.map(item => item.traffic_balance)) * 1.1 + 2).toFixed(2))]}
                                                        />
                                                        <Tooltip content={<CustomTooltip2 />} />
                                                        <Area
                                                            type="monotone"
                                                            dataKey="traffic_balance"
                                                            stroke="#4E9AA5"
                                                            fillOpacity={1}
                                                            strokeWidth={2}
                                                            fill="transparent"
                                                        />
                                                    </AreaChart>
                                                </ResponsiveContainer> : t("name-no-data")
                                        }
                                    </div>
                                </div>
                                : ''
                        }
                        {
                            currentTab === 'tab-whitelist' ?
                                <>
                                    <div className="whitelist-wrapper">
                                        <div className="form">
                                            <div>
                                                <h2 className="text-h2">{t("name-ip-adresses-in-whitelist")}</h2>
                                                <a className="link" href="/support/whitelisting-ips" target="_blank" rel="noopener noreferrer">
                                                    <b>{t("name-guide")}</b>
                                                </a>
                                                <table className="table_fade">
                                                    <thead>
                                                        <th>{t("name-ip")}</th>
                                                        <th>{t("name-type")}</th>
                                                        <th>{t("name-port")}</th>
                                                        <th>{t("name-configuration")}</th>
                                                        <th>{t("name-actions")}</th>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            availableWhitelist && availableWhitelist?.length > 0 ?
                                                                availableWhitelist.map((item) => (
                                                                    <tr>
                                                                        <td data-label={t("name-ip")}>{item?.ip}</td>
                                                                        <td data-label={t("name-type")}>{item?.type}</td>
                                                                        <td data-label={t("name-port")}>{item?.port}</td>
                                                                        <td data-label={t("name-configuration")}>{item?.configuration}</td>
                                                                        <td data-label={t("name-actions")}>
                                                                            <div className="buttons-wrapper" style={{ gap: "8px" }}>
                                                                                <button className="edit-button" onClick={(e) => (handleWhitelistEntryEdit(item))}>
                                                                                    <img src="/edit-3-svgrepo-com.svg" alt="" />
                                                                                </button>
                                                                                <button className="delete-button" onClick={(e) => (handleWhitelistEntryRemove(item?.hash))}>
                                                                                    <img src="/delete-2-svgrepo-com.svg" alt="" />
                                                                                </button>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                                :
                                                                <tr>
                                                                    <td colSpan="6" style={{ textAlign: "center", color: "rgb(133, 133, 133)" }}>{t("name-no-data")}</td>
                                                                </tr>
                                                        }
                                                    </tbody>
                                                </table>
                                                <div className="primary-btn" onClick={(e) => (setIsOpenWhitelistPopup(true))}>{t("button-add")}</div>
                                            </div>
                                        </div>
                                        <div className="form">
                                            <h2 className="text-h2">{t("name-formatted-proxy-list-server")}</h2>
                                            <div className="form-section">
                                                <div className="form-section__header">
                                                    <h3 className="text-h3">{t("name-whitelist")}</h3>
                                                </div>
                                                <div className="form-section__content">
                                                    <SelectComponent
                                                        selectedItem={selectedWhitelist}
                                                        items={availableWhitelist.map((item) => ({ name: `${item.ip} (${item.type})`, value: item }))}
                                                        disabled={!availableWhitelist || availableWhitelist?.length <= 0}
                                                        onItemSelected={setSelectedWhitelist}></SelectComponent>
                                                </div>
                                            </div>
                                            <label className="label" htmlFor="">{t("name-ip-port")}</label>
                                            <textarea className="textarea" disabled value={formattedProxyLists.join('\n')} onChange={(e) => setFormattedProxyLists(e.target.value)} name="" id=""></textarea>
                                            <div className="form-section">
                                                <div className="form-section__content">
                                                    <div className="grid-wrapper">
                                                        <div className="primary-btn" onClick={handleFormattedCopy}>{t('name-copy')}</div>
                                                        <DropdownButton title={t("button-downloads-list")} options={availableFormattedProxylistDownloadOptions} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-section">
                                                <div className="form-section__header">
                                                    <h3 className="text-h3">{t("name-count")}</h3>
                                                </div>
                                                <div className="form-section__content">
                                                    <div className="input-wrapper_fade">
                                                        <input
                                                            className="input"
                                                            value={selectedFormattedQuantity}
                                                            onChange={(e) => {
                                                                const value = Math.min(e.target.value, 1000);
                                                                setSelectedFormattedQuantity(value);
                                                            }}
                                                            type="number"
                                                            max="1000"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Popup title={t("popup-title-whitelist-entry-create")} trigger={isOpenWhitelistPopup} setTrigger={setIsOpenWhitelistPopup}>
                                        <div className="popup__content">
                                            <div className="form-section">
                                                <div className="form-section__content">
                                                    <a className="link" href="/support/pool-1-terms" target="_blank" rel="noopener noreferrer">
                                                        <b>{t("name-pool-1-terms")}</b>
                                                    </a>
                                                </div>
                                            </div>

                                            <div className="form-section">
                                                <div className="form-section__header">
                                                    <h3 className="text-h3"><b>{t("name-configuration")}</b></h3>
                                                </div>
                                                <div className="form-section__content">
                                                    <div className="flex-wrapper space-between">
                                                        <div className="flex-wrapper">
                                                            <div className="checkbox-wrapper">
                                                                <input
                                                                    className="switch"
                                                                    type="checkbox"
                                                                    checked={newWhitelistEntrySelectedHighEndPool}
                                                                    onChange={(e) => setNewWhitelistEntrySelectedHighEndPool(e.target.checked)}
                                                                    id="high-end-pool-checkbox"
                                                                />
                                                                <label className="label" htmlFor="high-end-pool-checkbox">
                                                                    <HintComponent
                                                                        title={t('name-high-end-pool')}
                                                                        content={t('hint-high-end-pool')}
                                                                    />
                                                                </label>
                                                            </div>
                                                            <div className="checkbox-wrapper">
                                                                <input
                                                                    className="switch"
                                                                    type="checkbox"
                                                                    checked={newWhitelistEntrySelectedSkipStatic}
                                                                    onChange={(e) => setNewWhitelistEntrySelectedSkipStatic(e.target.checked)}
                                                                    id="skip-static-checkbox"
                                                                />
                                                                <label className="label" htmlFor="skip-static-checkbox">
                                                                    {t('name-skip-asp-static')}
                                                                </label>
                                                            </div>

                                                        </div>
                                                        <button className="primary-btn" onClick={handleNewWhitelistEntryResetOptions}>{t("button-reset-options")}</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-section">
                                                <div className="form-section__content">
                                                    <div className="grid-wrapper">
                                                        <SelectSearchComponent
                                                            label={t('name-country-or-region')}
                                                            disabled={!residentialProxyData?.available_countries?.countries || residentialProxyData.available_countries.countries?.length <= 0}
                                                            multiple={false}
                                                            items={residentialProxyData.available_countries.countries}
                                                            selectedItems={newWhitelistEntrySelectedCountry}
                                                            onItemsSelected={setNewWhitelistEntrySelectedCountry}
                                                        ></SelectSearchComponent>
                                                        <SelectSearchComponent
                                                            label={t('name-city')}
                                                            disabled={!newWhiteListEntryAvailableCities || newWhiteListEntryAvailableCities?.length <= 0}
                                                            multiple={false}
                                                            items={newWhiteListEntryAvailableCities}
                                                            selectedItems={newWhitelistEntrySelectedState}
                                                            onItemsSelected={setNewWhitelistEntrySelectedState}
                                                        ></SelectSearchComponent>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-section">
                                                <div className="form-section__content">
                                                    <div className="grid-wrapper">
                                                        <SelectComponent label={t('name-rotation')} disabled={!residentialProxyData?.available_rotations || residentialProxyData?.available_rotations?.length <= 0} items={residentialProxyData.available_rotations} selectedItem={newWhitelistEntrySelectedRotation} onItemSelected={setNewWhitelistEntrySelectedRotation}></SelectComponent>
                                                        <SelectComponent label={t('name-protocol') + "*"} disabled={!availableProtocols || availableProtocols?.length <= 0} items={availableProtocols} selectedItem={newWhitelistEntrySelectedType} onItemSelected={setNewWhitelistEntrySelectedType}></SelectComponent>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-section">
                                                <div className="form-section__content">
                                                    <div className="grid-wrapper">
                                                        <div className="form-section">
                                                            <div className="form-section__header">
                                                                <h3 className="text-h3">{t("name-rotation-interval")}</h3>
                                                            </div>
                                                            <div className="form-section__content">
                                                                <div className="input-wrapper_fade">
                                                                    <input className="input" disabled={newWhitelistEntrySelectedRotation?.value !== 'sticky'} min={1} max={newWhitelistEntrySelectedRotationIntervalTimeFormat.value === "s" ? 86400 : newWhitelistEntrySelectedRotationIntervalTimeFormat.value === "m" ? 1440 : 24} value={newWhitelistEntrySelectedRotationInterval} placeholder={`${t("name-from")} 1 ${t("name-to")} ${newWhitelistEntrySelectedRotationIntervalTimeFormat.value === "s" ? 86400 : newWhitelistEntrySelectedRotationIntervalTimeFormat.value === "m" ? 1440 : 24}`} onChange={(e) => (handleNewWhitelistEntrySelectedRotationInterval(e.target.value))} type="number" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <SelectComponent label={t('name-time-format')} disabled={newWhitelistEntrySelectedRotation?.value !== 'sticky'} items={available_time_formats} selectedItem={newWhitelistEntrySelectedRotationIntervalTimeFormat} onItemSelected={setNewWhitelistEntrySelectedRotationIntervalTimeFormat}></SelectComponent>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-section">
                                                <div className="form-section__content">
                                                    <div className="grid-wrapper">
                                                        <SelectComponent
                                                            label={t('name-ipskipping')}
                                                            disabled={!availableIPSkippingList || availableIPSkippingList?.length <= 0}
                                                            items={availableIPSkippingList.map((item) => ({ name: item.title, value: item.hash }))}
                                                            selectedItem={newWhitelistEntrySelectedIPSkipping}
                                                            onItemSelected={setNewWhitelistEntrySelectedIPSkipping}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-section">
                                                <div className="form-section__header">
                                                    <h3 className="text-h3"><b>{t("name-options")}</b></h3>
                                                </div>
                                                <div className="form-section__content">
                                                    <div className="form-section">
                                                        <div className="form-section__header">
                                                            <h3 className="text-h3">{t("name-ip")}*</h3>
                                                            <HintComponent content={t("hint-whitelist-ip-pool-2")} />
                                                        </div>
                                                        <div className="form-section__content">
                                                            <div className="input-wrapper_fade">
                                                                <input className="input" placeholder={t("name-ip")} type="text" value={newWhitelistEntrySelectedIP} onChange={(e) => (setNewWhitelistEntrySelectedIP(e.target.value))} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {availablePorts && newWhitelistEntrySelectedType && <SelectComponent label={t('name-port')} items={availablePorts?.[newWhitelistEntrySelectedType.value] || []} selectedItem={newWhitelistEntrySelectedPort} onItemSelected={setNewWhitelistEntrySelectedPort}></SelectComponent>}
                                                </div>
                                            </div>
                                            <div className="form-section">
                                                <div className="form-section__header">
                                                    <h3 className="text-h3">{t("name-configuration")}</h3>
                                                </div>
                                                <div className="form-section__content">
                                                    <div className="input-wrapper">
                                                        <input
                                                            className="input"
                                                            type="text"
                                                            disabled={true}
                                                            value={newWhitelistEntryConfiguration}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="popup__footer">
                                            <button className="primary-btn" disabled={!newWhitelistEntrySelectedIP || !newWhitelistEntrySelectedPort || !newWhitelistEntrySelectedType} onClick={handleWhitelistEntryAdd}>{t("button-whitelist-entry-add")}</button>
                                        </div>
                                    </Popup>
                                    <Popup title={t("popup-title-whitelist-entry-edit")} trigger={isOpenWhitelistEditPopup} setTrigger={setIsOpenWhitelistEditPopup}>
                                        <div className="popup__content">
                                            <div className="form-section">
                                                <div className="form-section__content">
                                                    <a className="link" href="/support/pool-1-terms" target="_blank" rel="noopener noreferrer">
                                                        <b>{t("name-pool-1-terms")}</b>
                                                    </a>
                                                </div>
                                            </div>

                                            <div className="form-section">
                                                <div className="form-section__header">
                                                    <h3 className="text-h3"><b>{t("name-configuration")}</b></h3>
                                                </div>
                                                <div className="form-section__content">
                                                    <div className="flex-wrapper space-between">
                                                        <div className="flex-wrapper">
                                                            <div className="checkbox-wrapper">
                                                                <input
                                                                    className="switch"
                                                                    type="checkbox"
                                                                    checked={editWhitelistEntrySelectedHighEndPool}
                                                                    onChange={(e) => setEditWhitelistEntrySelectedHighEndPool(e.target.checked)}
                                                                    id="high-end-pool-checkbox"
                                                                />
                                                                <label className="label" htmlFor="high-end-pool-checkbox">
                                                                    <HintComponent
                                                                        title={t('name-high-end-pool')}
                                                                        content={t('hint-high-end-pool')}
                                                                    />
                                                                </label>
                                                            </div>
                                                            <div className="checkbox-wrapper">
                                                                <input
                                                                    className="switch"
                                                                    type="checkbox"
                                                                    checked={editWhitelistEntrySelectedSkipStatic}
                                                                    onChange={(e) => setEditWhitelistEntrySelectedSkipStatic(e.target.checked)}
                                                                    id="skip-static-checkbox"
                                                                />
                                                                <label className="label" htmlFor="skip-static-checkbox">
                                                                    {t('name-skip-asp-static')}
                                                                </label>
                                                            </div>

                                                        </div>
                                                        <button className="primary-btn" onClick={handleNewWhitelistEntryResetOptions}>{t("button-reset-options")}</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-section">
                                                <div className="form-section__content">
                                                    <div className="grid-wrapper">
                                                        <SelectSearchComponent
                                                            label={t('name-country-or-region')}
                                                            disabled={!residentialProxyData?.available_countries?.countries || residentialProxyData.available_countries.countries?.length <= 0}
                                                            multiple={false}
                                                            items={availableCountries}
                                                            selectedItems={editWhitelistEntrySelectedCountry}
                                                            onItemsSelected={setEditWhitelistEntrySelectedCountry}
                                                        ></SelectSearchComponent>
                                                        <SelectSearchComponent
                                                            label={t('name-city')}
                                                            items={editWhitelistEntryAvailableCities}
                                                            disabled={!editWhitelistEntryAvailableCities || editWhitelistEntryAvailableCities?.length <= 0}
                                                            multiple={false}
                                                            selectedItems={editWhitelistEntrySelectedState}
                                                            onItemsSelected={setEditWhitelistEntrySelectedState}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-section">
                                                <div className="form-section__content">
                                                    <div className="grid-wrapper">
                                                        <SelectComponent label={t('name-rotation')} items={residentialProxyData.available_rotations} selectedItem={editWhitelistEntrySelectedRotation} onItemSelected={setEditWhitelistEntrySelectedRotation}></SelectComponent>
                                                        <SelectComponent label={t('name-protocol') + "*"} disabled={true} items={availableProtocols} selectedItem={editWhitelistEntrySelectedType} onItemSelected={setEditWhitelistEntrySelectedType}></SelectComponent>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-section">
                                                <div className="form-section__content">
                                                    <div className="grid-wrapper">
                                                        <div className="form-section">
                                                            <div className="form-section__header">
                                                                <h3 className="text-h3">{t("name-rotation-interval")}</h3>
                                                            </div>
                                                            <div className="form-section__content">
                                                                <div className="input-wrapper_fade">
                                                                    <input className="input" disabled={editWhitelistEntrySelectedRotation?.value !== 'sticky'} min={1} max={editWhitelistEntrySelectedRotationIntervalTimeFormat.value === "s" ? 86400 : editWhitelistEntrySelectedRotationIntervalTimeFormat.value === "m" ? 1440 : 24} value={editWhitelistEntrySelectedRotationInterval} placeholder={`${t("name-from")} 1 ${t("name-to")} ${editWhitelistEntrySelectedRotationIntervalTimeFormat.value === "s" ? 86400 : editWhitelistEntrySelectedRotationIntervalTimeFormat.value === "m" ? 1440 : 24}`} onChange={(e) => (handleEditWhitelistEntrySelectedRotationInterval(e.target.value))} type="number" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <SelectComponent label={t('name-time-format')} disabled={editWhitelistEntrySelectedRotation?.value !== 'sticky'} items={available_time_formats} selectedItem={editWhitelistEntrySelectedRotationIntervalTimeFormat} onItemSelected={setEditWhitelistEntrySelectedRotationIntervalTimeFormat}></SelectComponent>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-section">
                                                <div className="form-section__content">
                                                    <div className="grid-wrapper">
                                                        <SelectComponent
                                                            label={t('name-ipskipping')}
                                                            disabled={!availableIPSkippingList || availableIPSkippingList?.length <= 0}
                                                            items={availableIPSkippingList.map((item) => ({ name: item.title, value: item.hash }))}
                                                            selectedItem={editWhitelistEntrySelectedIPSkipping}
                                                            onItemSelected={setEditWhitelistEntrySelectedIPSkipping}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-section">
                                                <div className="form-section__header">
                                                    <h3 className="text-h3"><b>{t("name-options")}</b></h3>
                                                </div>
                                                <div className="form-section__content">
                                                    <div className="form-section">
                                                        <div className="form-section__header">
                                                            <h3 className="text-h3">{t("name-ip")}*</h3>
                                                        </div>
                                                        <div className="form-section__content">
                                                            <div className="input-wrapper_fade">
                                                                <input className="input" placeholder={t("name-ip")} type="text" disabled={true} value={editWhitelistEntrySelectedIP} onChange={(e) => (setEditWhitelistEntrySelectedIP(e.target.value))} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <SelectComponent label={t('name-port') + "*"} items={availablePorts} disabled={true} selectedItem={editWhitelistEntrySelectedPort} onItemSelected={setEditWhitelistEntrySelectedPort}></SelectComponent> */}
                                                </div>
                                            </div>
                                            <div className="form-section">
                                                <div className="form-section__header">
                                                    <h3 className="text-h3">{t("name-configuration")}</h3>
                                                </div>
                                                <div className="form-section__content">
                                                    <div className="input-wrapper">
                                                        <input
                                                            className="input"
                                                            type="text"
                                                            disabled={true}
                                                            value={editWhitelistEntryConfiguration}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="popup__footer">
                                            <button className="primary-btn" disabled={!editWhitelistEntrySelectedIP || !editWhitelistEntrySelectedPort || !editWhitelistEntrySelectedType} onClick={handleWhitelistEntryEditSubmit}>{t("button-submit-changes")}</button>
                                        </div>
                                    </Popup>
                                </>
                                : ''
                        }
                        {
                            currentTab === 'tab-ipskipping' ?
                                <>
                                    <div className="ipskipping-wrapper">
                                        <div className="form">
                                            <div>
                                                <h2 className="text-h2">{t("name-ip-adresses-in-whitelist")}</h2>
                                                <table className="table_fade">
                                                    <thead>
                                                        <th>{t("name-title")}</th>
                                                        <th>{t("name-actions")}</th>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            availableIPSkippingList && availableIPSkippingList?.length > 0 ?
                                                                availableIPSkippingList.map((item) => (
                                                                    <tr>
                                                                        <td data-label={t("name-title")} style={{ width: "100%" }}>{item?.title}</td>
                                                                        <td data-label={t("name-actions")}>
                                                                            <div className="buttons-wrapper" style={{ gap: "8px" }}>
                                                                                <button className="edit-button" onClick={(e) => (handleIpSkippingEditItem(item))}>
                                                                                    <img src="/edit-3-svgrepo-com.svg" alt="" />
                                                                                </button>
                                                                                <button className="delete-button" onClick={(e) => (handleIPSkippingListRemove(item?.hash))}>
                                                                                    <img src="/delete-2-svgrepo-com.svg" alt="" />
                                                                                </button>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                                :
                                                                <tr>
                                                                    <td colSpan="3" style={{ textAlign: "center", color: "rgb(133, 133, 133)" }}>{t("name-no-data")}</td>
                                                                </tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="form">
                                            <h2 className="text-h2">
                                                {t("name-create-new-list")}
                                            </h2>
                                            <div className="form-section">
                                                <div className="form-section__header">
                                                    <h3 className="text-h3">{t("name-title")}</h3>
                                                </div>
                                                <div className="form-section__content">
                                                    <div className="input-wrapper_fade">
                                                        <input className="input" value={newIPSkippingListTitle} placeholder={t('name-title')} onChange={(e) => (setNewIPSkippingListTitle(e.target.value))} type="text" />
                                                    </div>
                                                </div>
                                            </div>
                                            <button className="primary-btn" disabled={!newIPSkippingListTitle || newIPSkippingListTitle?.length <= 1} onClick={(e) => (handleIPSkippingListAdd())}>{t("name-create")}</button>
                                            <br />
                                            <a className="link" href="/support/ip-skipping" target="_blank" rel="noopener noreferrer">
                                                <b>{t("name-guide")}</b>
                                            </a>
                                        </div>
                                    </div>
                                    <Popup title={t("popup-title-ipskipping-entry-edit")} trigger={isOpenIpskippingPopup} setTrigger={setIsOpenIpskippingPopup}>
                                        <div className="popup__content">
                                            <div className="form-section">
                                                <div className="form-section__header">
                                                    <h3 className="text-h3">{t("name-title")}</h3>
                                                </div>
                                                <div className="form-section__content">
                                                    <div className="input-wrapper_fade">
                                                        <input
                                                            className="input"
                                                            value={ipSkippingEditItem?.title || ''}
                                                            placeholder={t('name-title')}
                                                            onChange={(e) => setIpSkippingEditItem({
                                                                ...ipSkippingEditItem,
                                                                title: e.target.value
                                                            })}
                                                            type="text"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {ipSkippingEditItem?.items && ipSkippingEditItem.items.map((item, index) => (
                                                <div className="form-section" key={index}>
                                                    <div className="form-section__header">
                                                        <h3 className="text-h3">{t("name-ip-range")}</h3>
                                                    </div>
                                                    <div className="form-section__content">
                                                        <div className="flex-wrapper">
                                                            <div className="input-wrapper_fade">
                                                                <input
                                                                    className="input"
                                                                    value={item?.ip_range}
                                                                    placeholder={'1.0.0.1/23'}
                                                                    onChange={(e) => {
                                                                        const updatedRanges = [...ipSkippingEditItem.items];
                                                                        updatedRanges[index] = e.target.value;
                                                                        setIpSkippingEditItem({
                                                                            ...ipSkippingEditItem,
                                                                            items: updatedRanges
                                                                        });
                                                                    }}
                                                                    type="text"
                                                                />
                                                            </div>
                                                            <button
                                                                type="button"
                                                                className="delete-button"
                                                                onClick={() => handleIPSkippingEditItemRemoveRange(index)}
                                                                disabled={ipSkippingEditItem.items.length <= 1}
                                                            >
                                                                {t("button-delete")}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                            <button className="primary-btn primary-btn_unfilled" onClick={handleIPSkippingEditItemAddRange}>{t("button-add")}</button>
                                        </div>
                                        <div className="popup__footer">
                                            <div className="grid-wrapper">
                                                <button className="primary-btn primary-btn_unfilled" onClick={(e) => (setIsOpenIpskippingPopup(false))}>{t("button-cancel")}</button>
                                                <button className="primary-btn" disabled={ipSkippingEditItem?.items && ipSkippingEditItem?.items.length <= 0} onClick={handleIPSkippingListUpdate}>{t("button-save")}</button>
                                            </div>
                                        </div>
                                    </Popup>
                                </>
                                : ''
                        }
                    </>
            }
        </>
    );
};