import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import style from '../styles/Contacts.module.scss';

export const ContactsPage = () => {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>{t("page-title-contacts")}</title>
                <meta name="description" content={t("page-description-contacts")} />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href={window.location.href} />
                <meta property="og:title" content={t("page-title-contacts")} />
                <meta property="og:description" content={t("page-description-contacts")} />
            </Helmet>
            <main>
                <div className={style['contacts-main']}>
                    <h2 className={`${['h2-title']} ${style.title}`}>
                        {t('name-contacts-page')}
                    </h2>
                    <div className={style['contacts-wrapper']}>
                        <ul className={style['contacts-list']}>
                            <li>
                                <a href='tel:+447457409035'>
                                    <img src='/phone.svg' alt="Phone" />
                                    <span>+44 7457 409035</span>
                                </a>
                            </li>
                            <li>
                                <a href='https://t.me/psbhosting'>
                                    <img src='/telegram.svg' alt="Telegram" />
                                    <span>https://t.me/psbhosting</span>
                                </a>
                            </li>
                            <li>
                                <a href='mailto:support@psbproxy.io'>
                                    <img src='/mail (2).svg' alt="Mail" />
                                    <span>support@psbproxy.io</span>
                                </a>
                            </li>
                            <li>
                                <a href='https://t.me/psbnewschannel'>
                                    <img src='/telegram.svg' alt="Telegram" />
                                    <span>https://t.me/psbnewschannel</span>
                                </a>
                            </li>
                        </ul>
                        <div className={style['contacts-map']}>
                            <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2479.661626961049!2d-0.4312570235853179!3d51.574436371828625!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487605ad49cf9881%3A0xa7310a9f2fde3071!2sMint%20Formations%20Ltd!5e0!3m2!1sru!2sua!4v1721242686893!5m2!1sru!2sua" width="600" height="450" style={{border: 0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
                <div className={style['separator']}></div>
                <div className={style['contacts-main']}>
                    <h2 className={`${['h2-title']} ${style.title}`}>
                        {t('page-title-legal')}
                    </h2>
                    <p className={style['p-text']} style={{color: "rgb(136, 136, 136)"}}>
                        PSB HOSTING LTD
                    </p>
                    <p className={style['p-text']}>
                        COMPANY NUMBER: 15682236
                    </p>
                    <br />
                    <p className={style['p-text']}>
                        REGISTERED OFFICE:
                    </p>
                    <p className={style['p-text']}>
                        2nd Floor College House 17 King Edwards Road, RUISLIP, London, HA4 7AE UNITED KINGDOM
                    </p>
                </div>
            </main>
        </>
    );
};
